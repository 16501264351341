import {
  Children,
  cloneElement,
  ElementRef,
  ReactElement,
  useEffect,
  useRef,
} from 'react';

import styles from './index.module.scss';
import { MenuProps } from './types';
import {
  animateHideMenu,
  showMenu,
  stopDisplayTimers,
} from './utils/display-menu-utils';

const hasLastMenuItemFocus = (
  menuList: HTMLUListElement,
  target: EventTarget,
) => {
  const selectableItems = menuList.querySelectorAll("[data-disabled='false']");
  const lastChild = selectableItems[selectableItems.length - 1];
  const isLastChild = lastChild!.contains(target as HTMLElement);

  return isLastChild;
};

const focusFirstActiveElement = (menuList: HTMLUListElement) => {
  if (!menuList) return;

  const target = menuList?.querySelector<HTMLElement>(
    'a,[tabindex="0"]:not([data-disabled="true"])',
  );
  target?.focus();
};

const Menu = ({
  id,
  className,
  mounted,
  requestUnmount,
  selectFirstItem,
  isSubMenu,
  direction,
  anchorEl,
  focusNextTargetOnClose,
  menuPortalTarget,
  children,
  onClick,
  onMouseEnter,
  onOpen,
  onClose,
  onEscape,
}: MenuProps) => {
  const menuListRef = useRef<ElementRef<'ul'>>(null);

  if (requestUnmount && menuListRef.current) {
    animateHideMenu(menuListRef.current);
  }

  const validChildren = Children.toArray(children).filter(Boolean);
  const listItems = validChildren.map((child: unknown, index) => {
    return cloneElement(child as ReactElement, {
      'data-index': index,
    });
  });

  const escapeFocus = () => {
    onEscape();
    if (focusNextTargetOnClose) {
      window.queueMicrotask(() => anchorEl?.focus());
    }
  };

  useEffect(() => {
    const menuList = menuListRef.current;

    if (!menuList || !anchorEl) return;

    if (mounted) {
      showMenu({
        anchorEl,
        menuList,
        isSubMenu,
        direction,
        menuPortalTarget,
      });
    }
  }, [anchorEl, direction, mounted, menuPortalTarget, isSubMenu]);

  useEffect(() => {
    if (selectFirstItem && menuListRef.current) {
      focusFirstActiveElement(menuListRef.current);
    }
  }, [selectFirstItem]);

  useEffect(() => {
    return () => {
      stopDisplayTimers();
    };
  }, []);

  return mounted ? (
    <ul
      ref={menuListRef}
      id={id}
      className={`${className} ${styles['list']}`}
      tabIndex={-1}
      role={mounted ? 'menu' : 'presentation'}
      data-is-open={mounted && requestUnmount === false}
      data-direction={direction}
      data-items-count={listItems.length}
      data-sub-menu={isSubMenu}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={() => {
        if (isSubMenu) escapeFocus();
      }}
      onKeyDown={(e) => {
        const menuList = menuListRef.current!;

        if (
          e.key === 'Escape' ||
          (e.key === 'ArrowLeft' && isSubMenu) ||
          (e.key === 'Tab' && hasLastMenuItemFocus(menuList, e.target))
        ) {
          e.stopPropagation();
          escapeFocus();
        }
      }}
      onAnimationEnd={(e) => {
        e.stopPropagation();
        if (e.animationName.includes('show-menu')) {
          selectFirstItem && focusFirstActiveElement(menuListRef.current!);
          onOpen(e);
        } else if (e.animationName.includes('hide-menu')) {
          menuListRef.current!.setAttribute('data-animate-hidden', 'false');
          onClose(e);
        }
      }}
    >
      {listItems}
    </ul>
  ) : null;
};

export { Menu };
