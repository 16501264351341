/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import { AMPER_SAND } from 'core/constants/app-constants';

type Props = {
  className?: string;
  title: string;
  items: string[];
};

const BlockList: FC<Props> = ({ className, title, items }) => {
  return (
    <article className={className}>
      <h4>{title}</h4>
      {items?.length > 0 ? (
        <ul className="text-lg">
          {items?.map((item, index) => <li key={index}>{item}</li>)}
        </ul>
      ) : (
        <p className="text-lg">{AMPER_SAND}</p>
      )}
    </article>
  );
};

export { BlockList };
