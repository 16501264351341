import { parseAsuTerm } from 'core/utils/date-utils';

function processValidationWarnings(
  validationErrors: API.PlanData.PlanValidationError,
) {
  if (!validationErrors) return [];

  const courseValidations: API.PlanData.PlanValidationError[] = Array.isArray(
    validationErrors,
  )
    ? validationErrors
    : [validationErrors];

  const plainWarnings = courseValidations
    .filter((warning) => typeof warning === 'object')
    .sort((w1, w2) => {
      const a =
        w1.termCode + (w1.sessionCode || '') + w1.subject + w1.catalogNumber;
      const b =
        w2.termCode + (w2.sessionCode || '') + w2.subject + w2.catalogNumber;
      return a.localeCompare(b);
    });
  return plainWarnings;
}

function groupSortWarningByTermSession(
  warnings: API.PlanData.PlanValidationError[],
) {
  if (!warnings) return [];

  const map = new Map<string, API.PlanData.GroupedWarning>();

  warnings.forEach((warning) => {
    if (!warning.validationError) warning.validationError = [];

    const { termCode, sessionCode } = warning;
    const key = termCode + (sessionCode ? '-' + sessionCode : '');
    const title =
      parseAsuTerm(termCode).sessionYear +
      (sessionCode ? ' - Session ' + sessionCode : '');

    if (!map.has(key)) {
      map.set(key, { title: '', warnings: [] });
    }

    const data = map.get(key);
    data!.title = title;
    data!.warnings.push(warning);
  });

  const sortedList = Array.from(map);

  sortedList.sort(([keyA], [keyB]) => keyA.localeCompare(keyB));

  sortedList.forEach(([_, item]) => {
    item.warnings.sort((a, b) =>
      (a.subject + '-' + a.catalogNumber).localeCompare(
        b.subject + '-' + b.catalogNumber,
      ),
    );
  });

  return sortedList;
}

function attachClassWarnings(
  classes: API.PlanData.Class[],
  warnings: API.PlanData.PlanValidationError[],
) {
  warnings.forEach((warning) => {
    const warningKey =
      String(warning.termCode) +
      String(warning.sessionCode) +
      String(warning.subject) +
      String(warning.catalogNumber);

    classes.forEach((sClass) => {
      const { termCode, sessionCode } = sClass._uiMetaData || {};

      const classKey =
        termCode +
        sessionCode! +
        String(sClass.subject) +
        String(sClass.catalogNumber);

      if (classKey === warningKey) {
        sClass._uiMetaData = {
          ...sClass._uiMetaData!,
          warning: warning,
        };
      }
    });
  });
}

export {
  attachClassWarnings,
  processValidationWarnings,
  groupSortWarningByTermSession,
};
