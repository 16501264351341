import { createSelector } from '@reduxjs/toolkit';

import { RootState, useAppSelector } from '..';

const selectPlanExtraData = createSelector(
  [(state: RootState) => state.plan, (state: RootState) => state.userProfile],
  (plan, userProfile) => {
    const ownedByCurrentUser = plan.lastConsumerEmplid === userProfile.emplid;
    const readonly =
      ownedByCurrentUser === false &&
      userProfile.permissions.canEditAllPlan === false;

    return { ownedByCurrentUser, readonly };
  },
);

const usePlanExtraDataSelector = () => useAppSelector(selectPlanExtraData);

export { selectPlanExtraData, usePlanExtraDataSelector };
