import React, { FC, forwardRef } from 'react';

import { ErrorMessage } from '../ErrorMessage';
import styles from './index.module.scss';

type Props = GenericFieldProps & {
  type: React.HTMLInputTypeAttribute;
  name?: string;
  className?: string;
  checked?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  boldLabel?: boolean;
  autoFocus?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
};

const InputField = forwardRef<HTMLInputElement, Props>(
  (
    {
      id: inputId,
      name,
      label,
      value,
      placeholder,
      checked,
      required,
      readonly = false,
      disabled = false,
      autoFocus = false,
      error,
      boldLabel = true,
      type = 'text',
      className = '',
      style = {},
      onChange,
    },
    ref,
  ) => {
    const id = inputId || label.replaceAll(' ', '-');
    let containerClasses;
    let inputClasses = '';
    let labelClasses = '';

    const isOptionInput = ['radio', 'checkbox'].includes(type);

    if (isOptionInput) {
      containerClasses = 'flex w-full flex-row items-center gap-2';
      inputClasses = 'w-auto opacity-0';
      labelClasses = 'ml-4';
    }

    const LabelElement = () => (
      <label
        data-checked={checked}
        htmlFor={id}
        className={`${labelClasses} relative inline-block w-full cursor-pointer ${
          !boldLabel ? 'font-normal' : ''
        }`}
      >
        {label}
      </label>
    );

    return (
      <div
        data-input-type={type}
        className={`${styles.container} ${className}`}
        style={style}
      >
        <div className={containerClasses}>
          {!isOptionInput && <LabelElement />}
          <input
            ref={ref}
            id={id}
            name={name}
            autoFocus={autoFocus}
            className={`form-control ${inputClasses}`}
            type={type}
            placeholder={placeholder}
            value={value}
            required={required}
            checked={checked}
            readOnly={readonly}
            disabled={disabled}
            onChange={readonly ? undefined : onChange}
          />
          {isOptionInput && <LabelElement />}
        </div>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </div>
    );
  },
);

export { InputField };
