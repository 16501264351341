import { createSelector } from '@reduxjs/toolkit';

import { RootState, useAppSelector } from 'core/store';
import { selectCurrentProfile } from './select-current-profile';

const selectActivePlan = createSelector(
  [
    (state: RootState) => state.planActive,
    (state: RootState) => selectCurrentProfile(state),
  ],
  (planActive, currentProfile) => {
    const plan = planActive;
    const profileMajor = currentProfile.major;

    // ================================================
    // Check PlanCode major Mismatch
    // ================================================
    const profilePLanCode = profileMajor.academicPlanCode;
    const activePLanCode = plan.data?.summary.academicPlanCode;

    let hasMajorMismatch = false;
    if (profilePLanCode && activePLanCode) {
      hasMajorMismatch = profilePLanCode !== activePLanCode;
    }
    // ================================================

    return {
      ...plan,
      hasMajorMismatch,
    };
  },
);

const useActivePlanSelector = () => useAppSelector(selectActivePlan);

export { selectActivePlan, useActivePlanSelector };
