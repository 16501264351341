import { ElementRef, FC, useEffect, useRef, useState } from 'react';
import { BaseButton } from '../BaseButton';
import {
  SlideEffectMenuGroup,
  useSlideEffectMenuContext,
} from './SlideEffectMenuGroup';
import { NavMenuItem } from '.';

type Props = {
  className?: string;
  items: NavMenuItem[];
  [x: `data-${string}`]: string;
  onItemClick?: (selectedItem: NavMenuItem) => void;
};

const SlideEffectMenubar: FC<Props> = ({
  className,
  items,
  onItemClick = () => null,
  ...dataProps
}) => {
  const [keydown, setKeydown] = useState(false);
  const ref = useRef<ElementRef<'ul'>>(null);
  const [activeItem, setActiveItem] = useState<NavMenuItem>();

  const { isParent, onItemBlur, onItemFocus, setActiveNode } =
    useSlideEffectMenuContext();

  if (!isParent) {
    throw new Error(
      `"${SlideEffectMenubar.name}" must be used inside a "${SlideEffectMenuGroup.name}" component `,
    );
  }

  useEffect(() => {
    setActiveNode(items);
  }, [items, setActiveNode]);

  useEffect(() => {
    const parent = window.document;

    const handleKeydownTrue = () => setKeydown(true);
    const handleKeydownFalse = () => setKeydown(false);

    parent.addEventListener('keydown', handleKeydownTrue);
    parent.addEventListener('mousedown', handleKeydownFalse);

    return () => {
      parent.removeEventListener('keydown', handleKeydownTrue);
      parent.removeEventListener('mousedown', handleKeydownFalse);
    };
  }, []);

  // TODO: recompute menu border position when container resize
  // TODO: const refSize = useRef<number>(0);
  // useEffect(() => {
  //   if (!ref.current) return;

  //   refSize.current = ref.current.offsetWidth;

  //   const resizeObserver = new ResizeObserver((entries) => {
  //     const entry = entries[0];

  //     const newWidth = Math.round(entry.contentRect.width)
  //     const oldWidth = Math.round(refSize.current)
  //     if (newWidth > 0 && newWidth !== oldWidth) {
  //       activeItem && onItemClick(activeItem);
  //       refSize.current = newWidth;
  //     }
  //   });
  //   resizeObserver.observe(ref.current);

  //   return () => resizeObserver.disconnect();
  // }, [activeItem, onItemClick]);

  return (
    <ul
      ref={ref}
      className={`m-0 flex gap-6 p-0 ${className || ''}`}
      role="navigation"
      {...dataProps}
    >
      {items.map((item) => (
        <li
          key={item.id}
          className={`flex-grow ${item.selected ? 'font-bold' : ''}`}
        >
          <BaseButton
            id={item.id}
            role="link"
            className={`link-navbar w-full leading-none
                        ${!keydown ? 'no-active-focus' : ''}
                        ${item.selected ? 'text-shadow-bold' : ''}
            `}
            onClick={() => {
              setActiveItem(item);
              onItemClick(item);
            }}
            onFocus={onItemFocus}
            onBlur={onItemBlur}
            onMouseOver={onItemFocus}
            onMouseLeave={onItemBlur}
          >
            {item.text}
          </BaseButton>
        </li>
      ))}
    </ul>
  );
};

export { SlideEffectMenubar };
