function pickEmplid(appState: unknown, useSearchProfile = false): string {
  const state = appState as AppState;
  const { viewAs, emplid, search } = state.userProfile;
  let currentEmplid = emplid;

  if (useSearchProfile) {
    currentEmplid = search.selectedUser?.profile.emplid || '';
  } else if (viewAs.active) {
    currentEmplid = viewAs.emplid;
  }

  return currentEmplid || '';
}

function processPermission(scopes: string[]) {
  const hasPermission = (key: string) =>
    scopes.some((value) => new RegExp(key).test(value));

  const permissions: UserPermission = {
    canReadSelfPlan: hasPermission('/plan/read:self'),
    canReadAllPlan: hasPermission('/plan/read:all'),
    canEditSelfPlan: hasPermission('/plan/write:self'),
    canEditAllPlan: hasPermission('/plan/write:all'),
    canReadSelfProfile: hasPermission('/profile/read:self'),
    canWriteSelfProfile: hasPermission('/profile/write:self'),
    canReadAllProfile: hasPermission('/profile/read:all'),
    canEditAllProfile: hasPermission('/profile/write:all'),
    canReadAllUsers: hasPermission('/users/read:all'),
    canVewAs: hasPermission('/token/view-as:all'),
  };

  return permissions;
}

const pickCurrentRoleSelector = (state: AppState) =>
  state.userProfile.viewAs.currentRole || state.userProfile.currentRole;

function parsePreferredName(profile: API.StudentData.Profile | null) {
  if (!profile) return '';

  const { firstName, lastName, preferredName } = profile;

  if (preferredName) return preferredName;
  if (firstName && lastName) return `${firstName} ${lastName}`;

  return '';
}

export {
  pickEmplid,
  pickCurrentRoleSelector,
  processPermission,
  parsePreferredName,
};
