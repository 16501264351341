import { AMPER_SAND } from 'core/constants/app-constants';
import { parseAsuTerm } from 'core/utils/date-utils';

function sumPlanCredits(
  studentProgress: API.PlanData.StudentProgress[],
  degreePlan: API.PlanData.Term[],
) {
  const completed =
    studentProgress.reduce((acc, cur) => acc + cur.completedCreditHour, 0) +
    degreePlan.reduce(
      (acc, cur) => acc + (cur._uiMetaData?.completedCreditHour || 0),
      0,
    );

  const inProgress = degreePlan.reduce(
    (acc, cur) => acc + cur._uiMetaData?.inProgressCreditHour!,
    0,
  );

  const registered = degreePlan.reduce(
    (acc, cur) => acc + cur._uiMetaData?.registeredCreditHour!,
    0,
  );

  const planned = degreePlan.reduce(
    (acc, cur) => acc + cur._uiMetaData?.plannedCreditHour!,
    0,
  );

  const remaining = degreePlan.reduce(
    (acc, cur) => acc + cur._uiMetaData?.remainingCreditHour!,
    0,
  );

  const total = completed + inProgress + registered + planned + remaining;

  return {
    completed,
    inProgress,
    registered,
    planned,
    remaining,
    total,
  };
}

function processSummaryData(plan: API.PlanData.Plan | null) {
  if (!plan) return;

  // Formats GPA and graduation term, and calculates total credit hours.
  const { studentProgress, degreePlan, summary } = plan;
  const { cumulativeGpa: GPA, targetGraduationTerm } = summary;

  const cumulativeGpa = !GPA || GPA === 'N/A' ? AMPER_SAND : GPA;
  const targetGraduation =
    (Number.isNaN(+targetGraduationTerm)
      ? targetGraduationTerm
      : parseAsuTerm(targetGraduationTerm).sessionYear) || AMPER_SAND;

  plan.summary._uiMetaData = {
    cumulativeGpa,
    targetGraduation,
    creditHours: sumPlanCredits(studentProgress, degreePlan),
  };

  const requirements = plan.summary.requirementSummaries;

  // Define the requirement map for UI display with guaranteed order
  const uiRequirementMap = new Map<string, string>([
    ['Major', 'Major'],
    ['First-Year Composition', 'First Year Composition'],
    ['General Studies', 'General Studies'],
    ['General Electives', 'University Electives'],
    ['Upper Division', 'Upper Division'],
    ['Resident Credit', 'ASU Resident Credit'],
    ['University Total Hours', 'University Total Hours'],
  ]);

  // Utility function to clean undefined values in a requirement
  const cleanRequirement = (
    req: API.SummaryData.Requirement,
  ): API.SummaryData.Requirement => ({
    ...req,
    earnedHours: req.earnedHours ?? 0,
    inProgressHours: req.inProgressHours ?? 0,
    requiredHours: req.requiredHours ?? 0,
    remainingHours: req.remainingHours ?? 0,
    registeredHours: req.registeredHours ?? 0,
  });

  // Sort requirements based on the UI map order
  const sortedRequirements: API.SummaryData.Requirement[] = [];

  for (const [name, title] of uiRequirementMap) {
    const req = requirements.find((req) => req.name === name);
    if (req) {
      sortedRequirements.push({ ...cleanRequirement(req), name: title });
    }
  }

  // Extract requirements not listed in the UI map
  const otherRequirements = requirements
    .filter((req) => !uiRequirementMap.has(req.name))
    .map(cleanRequirement);

  plan.summary.requirementSummaries = [
    ...sortedRequirements,
    ...otherRequirements,
  ];
}

export { processSummaryData };
