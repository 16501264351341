import { createSelector } from '@reduxjs/toolkit';

import { RootState, useAppSelector } from '..';

const selectTermList = createSelector(
  (state: RootState) => state.plan,
  (plan) => {
    const graduationTerms = plan.data.degreePlan.map((term) => ({
      label: term.termName,
      value: term.termName,
      termCode: term.term,
    }));

    return graduationTerms;
  },
);

const useTermSelector = () => useAppSelector(selectTermList);

export { selectTermList, useTermSelector };
