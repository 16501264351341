import { createSelector } from '@reduxjs/toolkit';

import { appRouterUrl, startPageByRole } from 'core/constants/router-url';
import { RootState, useAppSelector } from '..';

const selectDashboardUrl = createSelector(
  (state: RootState) => state.userProfile,
  (userProfile) => {
    const primaryRole =
      userProfile.viewAs.currentRole || userProfile.currentRole;
    const secondaryRole = userProfile.roles?.find(
      (otherRole) => startPageByRole[otherRole],
    );

    const dashboard =
      startPageByRole[String(primaryRole)] ||
      startPageByRole[String(secondaryRole)] ||
      appRouterUrl.NOT_FOUND;

    return dashboard;
  },
);

const useDashboardUrlSelector = () => useAppSelector(selectDashboardUrl);

export { selectDashboardUrl, useDashboardUrlSelector };
