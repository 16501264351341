import { FC } from 'react';

import { MenuItemProps, ReactKeyboardEvent } from './types';
import { findActiveSibling } from './utils/menu-utils';

const MenuItem: FC<MenuItemProps> = ({
  children,
  customTabIndex = false,
  disabled = false,
  readonly = false,
  onClick,
  ...props
}) => {
  const selectMenuItem = (e: ReactKeyboardEvent) => {
    e.stopPropagation();
    e.preventDefault();

    const target = e.currentTarget as HTMLElement;
    let sibling = null;

    if (e.key === 'ArrowUp') {
      sibling = findActiveSibling(target, 'previousSibling');
    } else if (e.key === 'ArrowDown') {
      sibling = findActiveSibling(target, 'nextSibling');
    }

    if (sibling) {
      const element = sibling.hasAttribute('tabindex')
        ? sibling
        : sibling.querySelector<HTMLElement>('a,[tabindex="0"]');

      if (element?.focus) {
        element.focus();
      }
    }
  };

  return (
    <li
      {...props}
      role="menuitem"
      tabIndex={customTabIndex || disabled || readonly ? undefined : 0}
      data-disabled={disabled}
      data-readonly={readonly}
      data-menu-item="true"
      onKeyDown={
        disabled
          ? undefined
          : (e) => {
              if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
                selectMenuItem(e);
              } else if (e.key === 'Enter') {
                e.currentTarget.click();
              }
            }
      }
      onClick={disabled ? undefined : onClick}
    >
      {children}
    </li>
  );
};

export { MenuItem };
