import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { authClient } from 'core/auth';
import { apiBaseUrl } from 'core/constants/app-settings';
import { getIDPAuthToken } from 'core/services/idp/user-service';

const API = axios.create({
  baseURL: apiBaseUrl,
});

const cachedData: Record<string, any> = {};

API.interceptors.request.use(async (config) => {
  const clientInstance = authClient?.getClientInstance();
  const serviceAuthToken = await clientInstance?.getAuthToken();
  const idpAuthToken = getIDPAuthToken();
  let jwt;

  if (config.headers['Use-ServiceAuth-Token']) {
    jwt = serviceAuthToken;
  } else {
    jwt = idpAuthToken;
  }

  config.headers['Authorization'] = `Bearer ${jwt?.token}`;

  return config;
});

type HttpParamUtils<TDataResponse> =
  | string
  | {
      url: string;
      config?: Partial<AxiosRequestConfig>;
      cache?: 'force-cache' | 'no-store';
      /** It process the Response.data object prior to be cached */
      cacheDataProcessor?: (data: TDataResponse) => TDataResponse;
    };

const httpFetcher = async <TDataResponse>(
  instance: AxiosInstance,
  cachedData: Record<string, any>,
  param: HttpParamUtils<TDataResponse>,
) => {
  const {
    url,
    config = {},
    cache = 'no-store',
    cacheDataProcessor,
  } = typeof param === 'object'
    ? param
    : {
        url: param,
      };

  if (cache === 'force-cache' && cachedData[url]) {
    return cachedData[url] as AxiosResponse<TDataResponse>;
  }

  const { data, status } = await instance.get<TDataResponse>(url, config);
  cachedData[url] = {
    data: cacheDataProcessor?.(data) || data,
    status,
    config,
  };

  return cachedData[url] as AxiosResponse<TDataResponse>;
};

const fetchData = async <TDataResponse>(
  param: HttpParamUtils<TDataResponse>,
) => {
  return httpFetcher<TDataResponse>(API, cachedData, param);
};

const postData = async <TDataResponse, TPayloadRequest = any>(
  url: string,
  data: TPayloadRequest,
  config: AxiosRequestConfig = {},
) => {
  return API.post<TDataResponse, AxiosResponse<TDataResponse>, TPayloadRequest>(
    url,
    data,
    config,
  );
};

const putData = async <TDataResponse>(
  url: string,
  data: any,
  config: AxiosRequestConfig = {},
) => {
  return API.put<TDataResponse>(url, data, {
    ...config,
  });
};

const deleteData = async <TDataResponse>(
  url: string,
  config: AxiosRequestConfig = {},
) => {
  return API.delete<TDataResponse>(url, {
    ...config,
  });
};

// Once the web API will be ready, this will be replaced either by `fetch` or `axios` API
function fakeData<T>(
  mockData = {} as T,
  delay = 200,
): Promise<AxiosResponse<T, any>> {
  return new Promise((resolve) =>
    setTimeout(() => {
      resolve({
        data: mockData,
        status: 200,
        statusText: 'OK',
      } as AxiosResponse<T, any>);
    }, delay),
  );
}

export {
  type HttpParamUtils,
  fetchData,
  postData,
  putData,
  deleteData,
  httpFetcher,
  fakeData,
  apiBaseUrl,
};
