import { useAppSelector } from '..';
import { pickCurrentRoleSelector } from '../slices/utils';

export const useContactValidatorSelector = (
  key: keyof StudentProfileState['validator'],
) => useAppSelector((state) => state.studentProfile.validator[key]);

export const useCurrentRoleSelector = () =>
  useAppSelector(pickCurrentRoleSelector);

export const useUserPermission = () =>
  useAppSelector((state) => state.userProfile.permissions);
