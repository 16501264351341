import { FC } from 'react';

type Props = {
  anchorEl: HTMLElement | null;
};

const MenuTooltip: FC<Props> = ({ anchorEl }) => {
  const anchorRect = anchorEl?.getBoundingClientRect();

  return anchorRect ? (
    <span
      className="absolute mt-1 w-max rounded border bg-black
                 p-2 text-xs font-bold text-white"
      style={{
        top: `calc(${anchorRect.bottom}px)`,
        left: `${anchorRect.left}px`,
        transform: `translateX(calc(-50% + ${anchorRect.width / 2}px))`,
      }}
    >
      More Actions
    </span>
  ) : null;
};

export { MenuTooltip };
