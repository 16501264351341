import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RoleName } from 'core/constants/app-constants';
import { appRouterUrl } from 'core/constants/router-url';
import {
  readUserSearchHistory,
  requestIDPAuthToken,
  searchUsers,
  storeCurrentRole,
  updateUserSearchHistory,
} from 'core/services/idp/user-service';
import {
  recoverViewAsUser,
  startViewAsUser,
  stopViewAsUser,
  storeViewAsUser,
} from 'core/services/ui/user-view-as-service';
import { extractUserId } from 'core/utils/data-utils';
import { resetHttpError } from './sharedSlice';
import {
  dispatchThunkError,
  pickCurrentRoleSelector,
  processPermission,
} from './utils';

const initialState: UserProfileState = {
  requestTokenLoading: false,
  requestTokenRole: null,
  emplid: null,
  roles: [],
  currentRole: null,
  primaryRole: null,
  viewAs: {
    userName: null,
    emplid: null,
    active: false,
    startPage: appRouterUrl.ADMIN_DASHBOARD,
    currentRole: undefined,
    loading: false,
    selectedUser: null,
    permissions: {
      canReadSelfPlan: false,
      canReadAllPlan: false,
      canEditSelfPlan: false,
      canEditAllPlan: false,
      canReadSelfProfile: false,
      canWriteSelfProfile: false,
      canReadAllProfile: false,
      canEditAllProfile: false,
      canReadAllUsers: false,
      canVewAs: false,
    },
  },
  search: {
    searchBy: '',
    loading: false,
    completed: false,
    data: [],
    selectedUser: null,
    localHistory: [],
  },
  permissions: {
    canReadSelfPlan: false,
    canReadAllPlan: false,
    canEditSelfPlan: false,
    canEditAllPlan: false,
    canReadSelfProfile: false,
    canWriteSelfProfile: false,
    canReadAllProfile: false,
    canEditAllProfile: false,
    canReadAllUsers: false,
    canVewAs: false,
  },
};

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    setRequestTokenLoading: (state, action: ActionOf<boolean>) => {
      state.requestTokenLoading = action.payload;
    },
    setRequestTokenRole: (state, action: ActionOf<UserProfileRole>) => {
      state.requestTokenRole = action.payload;
    },
    setSearchLoading: (state, action: ActionOf<boolean>) => {
      state.search.loading = action.payload;
    },
    setSearchCompleted: (state, action: ActionOf<boolean>) => {
      state.search.completed = action.payload;
    },
    setViewAsLoading: (state, action: ActionOf<boolean>) => {
      state.viewAs.loading = action.payload;
    },
    setViewAsRole: (state, action: ActionOf<UserProfileRole>) => {
      state.viewAs.currentRole = action.payload;
    },
    setViewAs: (state, action: ActionOf<Partial<UserViewAs>>) => {
      state.viewAs = {
        ...state.viewAs,
        ...action.payload,
      };
      storeViewAsUser(state.viewAs);
    },
    setViewAsFromStorage: (state) => {
      const localViewAsUser = recoverViewAsUser();
      state.viewAs = {
        ...state.viewAs,
        ...localViewAsUser,
      };
    },
    setViewAsPermissions: (state, action: ActionOf<string[]>) => {
      state.viewAs.permissions = processPermission(action.payload);
    },
    resumeUser: (state) => {
      state.viewAs = { ...initialState.viewAs };
      state.search = { ...initialState.search };
      stopViewAsUser();
    },
    setUserEmplid: (state, action: ActionOf<string>) => {
      state.emplid = action.payload;
    },
    setUserPermissions: (state, action: ActionOf<string[]>) => {
      state.permissions = processPermission(action.payload);
    },
    setCurrentRole: (state, action: ActionOf<UserProfileRole | null>) => {
      state.currentRole = action.payload;
      storeCurrentRole(state.currentRole);
    },
    setPrimaryRole: (state, action: ActionOf<UserProfileRole | null>) => {
      state.primaryRole = action.payload;
    },
    setUserRoles: (state, action: ActionOf<API.UserRole[]>) => {
      state.roles = action.payload;
    },
    setSearchBy: (state, action: ActionOf<string>) => {
      state.search.searchBy = action.payload;
    },
    setSearchData: (state, action: ActionOf<API.UserData.ProfileSearch[]>) => {
      state.search.data = action.payload;
    },
    loadSearchLocalHistory: (state) => {
      state.search.localHistory = readUserSearchHistory();
    },
    setSearchLocalHistory: (state, action: ActionOf<string[]>) => {
      state.search.localHistory = action.payload;
    },
    setSelectedProfile: (
      state,
      action: ActionOf<API.UserData.ProfileSearch | null>,
    ) => {
      state.search.selectedUser = action.payload;
    },
  },
});

export const getSearchUserByAsync = createAsyncThunk(
  'userProfileSlice/getSearchUserByAsync',
  async (
    payload: { searchBy: string; cacheSearch?: boolean },
    { dispatch, getState },
  ) => {
    dispatch(resetHttpError());
    dispatch(setSearchLoading(true));
    dispatch(setSearchCompleted(false));

    try {
      const { searchBy, cacheSearch } = payload;
      const users = await searchUsers(searchBy);

      let cachedValue = searchBy;

      if (users.length === 1) {
        const { profile } = users[0];
        // asurite
        const asurite = profile.email.split('@').shift();
        // User full name
        const { preferredName, firstName, lastName } = profile;
        const name = preferredName || firstName + ' ' + lastName;

        const info = `${name} (${asurite})`;
        cachedValue = profile.emplid + '-' + info;
      }

      cacheSearch && updateUserSearchHistory(cachedValue);
      dispatch(setSearchData(users));
      setSearchLocalHistory(readUserSearchHistory());

      if (users.length === 1) {
        dispatch(setSelectedProfile(users[0]));
      }

      const myCurrentRole = pickCurrentRoleSelector(getState() as AppState);

      let foundStudent = null;

      if (users?.length === 1) {
        const { roles } = users[0];
        if (
          roles?.includes(RoleName.STUDENT) ||
          // Admins are not allowed to impersonate other Admins
          (myCurrentRole === RoleName.ADMIN && roles?.includes(RoleName.ADMIN))
        ) {
          foundStudent = users[0];
        }
      }

      return { users, foundStudent };
    } catch (error) {
      dispatchThunkError(dispatch, error, getSearchUserByAsync);
      dispatch(setSearchData([]));
    } finally {
      dispatch(setSearchLoading(false));
      dispatch(setSearchCompleted(true));
    }
    return { users: [], foundStudent: null };
  },
);

export const setViewAsUserAsync = createAsyncThunk(
  'userProfileSlice/setViewAsUserAsync',
  async (
    payload: {
      userName: string;
      emplid: string;
      startPage: string;
    },
    { dispatch, getState },
  ) => {
    try {
      const state = getState() as AppState;
      const {
        currentRole,
        search: { selectedUser },
      } = state.userProfile;
      const { userName, emplid, startPage } = payload;

      const realData = selectedUser?.profile?.realData;
      const incognitoUserName = extractUserId(
        realData?.email || '',
        realData?.asurite,
      );

      dispatch(setViewAsLoading(true));

      startViewAsUser({
        viewAsUserName: incognitoUserName || userName,
        viewAsEmplid: emplid,
        viewAsUserRole: RoleName.STUDENT,
      });

      const data = await requestIDPAuthToken(currentRole!);
      const defaultRole =
        data?.viewAsRole || selectedUser?.roles?.[0] || data?.role;

      dispatch(setUserPermissions(data?.scope || []));
      dispatch(
        setViewAs({
          active: true,
          loading: false,
          currentRole: defaultRole,
          userName,
          emplid,
          startPage,
          selectedUser,
        }),
      );

      return {
        success: true,
        data,
        viewAsDefaultRole: defaultRole!,
      };
    } catch (error) {
      dispatchThunkError(dispatch, error, setViewAsUserAsync);
    } finally {
      dispatch(setViewAsLoading(false));
    }
  },
);

export const requestIDPAuthTokenRoleAsync = createAsyncThunk(
  'userProfileSlice/requestIDPAuthTokenRoleAsync',
  async (userRole: UserProfileRole, { dispatch }) => {
    try {
      dispatch(setRequestTokenRole(userRole));
      dispatch(setRequestTokenLoading(true));
      const data = await requestIDPAuthToken(userRole);
      dispatch(setUserPermissions(data?.scope || []));
    } catch (error) {
      dispatch(setUserPermissions([]));
      dispatchThunkError(dispatch, error, requestIDPAuthTokenRoleAsync);
    } finally {
      dispatch(setRequestTokenLoading(false));
    }
  },
);

export const {
  resumeUser,
  setUserRoles,
  setCurrentRole,
  setPrimaryRole,
  setViewAsLoading,
  setViewAs,
  setViewAsRole,
  setRequestTokenLoading,
  setRequestTokenRole,
  setSearchBy,
  setSearchLoading,
  setSearchCompleted,
  setSearchData,
  setSelectedProfile,
  setSearchLocalHistory,
  setUserEmplid,
  setViewAsFromStorage,
  setUserPermissions,
  loadSearchLocalHistory,
} = userProfileSlice.actions;

export default userProfileSlice.reducer;
