import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { generateId } from 'core/utils/generate-id';
import styles from './index.module.scss';
import { Menu } from './Menu';
import { MenuTooltip } from './MenuTooltip';
import { MenuAnchorProps, MenuListEvent, MenuListProps } from './types';

const MENU_SHOW_EVENT = 'menu-list-show';

let menuEnterTimer: NodeJS.Timer;
let menuCloseTimer: NodeJS.Timer;

const cleanupTimers = () => {
  clearTimeout(menuEnterTimer);
  clearTimeout(menuCloseTimer);
};

const MenuList: FC<MenuListProps> = ({
  children,
  anchorComponent,
  menuPortalTarget,
  id: propId,
  className,
  tooltip,
  isSubMenu = false,
  disabled = false,
  menuIsOpen = false,
  showOnMouseover = true,
  focusNextTargetOnClose = false,
  openingDelayTimeout = 300,
  closingDelayTimeout = 300,
  direction = 'right',
  onClick,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [skipClickEvent, setSkipClickEvent] = useState(false);
  const [isMenuMounted, setMenuMounted] = useState(menuIsOpen);
  const [isRequestedUnmountMenu, setRequestUnmountMenu] = useState(false);
  const [isFirstItemSelected, setFirstItemSelected] = useState(false);

  const anchorRef = useRef<HTMLElement>(null);
  const refKey = useRef(propId || generateId('menu-list-'));
  const id = refKey.current;

  const requestUnmountMenu = () => {
    setRequestUnmountMenu(true);
  };

  const mountMenu = useCallback(
    (e: MenuListEvent) => {
      setShowTooltip(false);
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }

      anchorRef.current!.focus({
        preventScroll: true,
      });

      setMenuMounted(true);
      setRequestUnmountMenu(false);

      if (isSubMenu) {
        setFirstItemSelected(true);
      } else {
        window.dispatchEvent(
          new CustomEvent(MENU_SHOW_EVENT, {
            detail: { menuId: id },
          }),
        );
      }
    },
    [id, isSubMenu],
  );

  const toggleMenu = (e: MenuListEvent) => {
    isMenuMounted ? requestUnmountMenu() : mountMenu(e);
  };

  const mountTooltip = () => {
    if (!isMenuMounted && tooltip && showOnMouseover === false) {
      setShowTooltip(true);
    }
  };

  // #region "Mouseover events"
  const handleMouseLeave = useCallback(
    (e: MenuListEvent) => {
      e.stopPropagation();
      menuCloseTimer = setTimeout(requestUnmountMenu, closingDelayTimeout);
    },
    [closingDelayTimeout],
  );

  const handleAnchorMouseEnter = useCallback(
    (e: MenuListEvent) => {
      e.stopPropagation();

      if (isMenuMounted) return;

      setSkipClickEvent(true);
      menuEnterTimer = setTimeout(() => {
        mountMenu(e);
      }, openingDelayTimeout);
    },
    [isMenuMounted, mountMenu, openingDelayTimeout],
  );

  const handleAnchorMouseLeave = useCallback((e: MenuListEvent) => {
    cleanupTimers();
    setSkipClickEvent(false);
  }, []);

  // //#endregion

  useEffect(() => {
    const closeOtherMenu = (e: Event) => {
      const event = e as CustomEvent;
      if (isMenuMounted && event.detail?.menuId !== id) {
        setMenuMounted(false);
      }
    };
    window.addEventListener(MENU_SHOW_EVENT, closeOtherMenu);

    return () => {
      window.removeEventListener(MENU_SHOW_EVENT, closeOtherMenu);
    };
  }, [id, isMenuMounted]);

  useEffect(() => {
    const events = ['click', 'resize'];

    const closeWithTransition = () => {
      requestUnmountMenu();
      setShowTooltip(false);
    };

    const closeOnScroll = () => {
      setMenuMounted(false);
      setShowTooltip(false);
    };

    events.forEach((name) =>
      window.addEventListener(name, closeWithTransition),
    );
    window.addEventListener('scroll', closeOnScroll, {
      capture: true,
    });

    return () => {
      events.forEach((name) =>
        window.removeEventListener(name, closeWithTransition),
      );
      window.removeEventListener('scroll', closeOnScroll, {
        capture: true,
      });
    };
  }, []);

  useEffect(() => {
    return () => cleanupTimers();
  }, []);

  const MenuPopup = (
    <Menu
      id={id}
      className={className}
      isSubMenu={isSubMenu}
      direction={direction}
      mounted={isMenuMounted}
      requestUnmount={isRequestedUnmountMenu}
      anchorEl={anchorRef.current}
      selectFirstItem={isFirstItemSelected}
      menuPortalTarget={menuPortalTarget}
      focusNextTargetOnClose={focusNextTargetOnClose}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.(e);
      }}
      onMouseEnter={(e) => {
        e.stopPropagation();
        cleanupTimers();
      }}
      onEscape={requestUnmountMenu}
      onOpen={() => {
        setSkipClickEvent(false);
      }}
      onClose={() => {
        setMenuMounted(false);
        setRequestUnmountMenu(false);
        setFirstItemSelected(false);
        if (isSubMenu) {
          anchorRef.current?.focus();
        }
      }}
    >
      {children}
    </Menu>
  );

  return (
    <div
      className={styles.container}
      onMouseLeave={showOnMouseover ? handleMouseLeave : undefined}
    >
      <div className={styles['anchor-element']}>
        {anchorComponent(
          disabled
            ? ({} as MenuAnchorProps)
            : {
                ref: anchorRef,
                tabIndex: 0,
                'aria-label': tooltip,
                'aria-controls': id,
                'aria-expanded': isMenuMounted,
                'aria-haspopup': 'true',
                onKeyDown: (e) => {
                  if (e.key === 'Escape') {
                    e.preventDefault();
                    requestUnmountMenu();
                  } else if (e.key === 'Tab') {
                    e.stopPropagation();
                    setMenuMounted(false);
                  } else if (e.key === 'Enter') {
                    e.preventDefault();
                    cleanupTimers();
                    toggleMenu(e);
                  } else if (e.key === 'ArrowDown') {
                    e.preventDefault();
                    setFirstItemSelected(true);
                  } else if (isSubMenu) {
                    if (e.key === 'ArrowRight') {
                      e.stopPropagation();
                      mountMenu(e);
                    } else if (e.key === 'ArrowLeft') {
                      e.stopPropagation();
                      setMenuMounted(false);
                    }
                  }
                },
                onClick: (e) => {
                  e.stopPropagation();
                  if (!skipClickEvent) {
                    toggleMenu(e);
                  }
                },
                onMouseEnter: showOnMouseover
                  ? handleAnchorMouseEnter
                  : undefined,
                onMouseLeave: showOnMouseover
                  ? handleAnchorMouseLeave
                  : undefined,
                onMouseOver: () => mountTooltip(),
                onFocus: () => mountTooltip(),
                onMouseOut: () => setShowTooltip(false),
                onBlur: (e) => setShowTooltip(false),
              },
        )}
      </div>

      {menuPortalTarget
        ? createPortal(MenuPopup, menuPortalTarget, refKey.current)
        : MenuPopup}

      {showTooltip && !isMenuMounted
        ? createPortal(
            <MenuTooltip anchorEl={anchorRef.current} />,
            document.body,
            'menu-tooltip',
          )
        : null}
    </div>
  );
};
//#endregion

export { MenuList };
export { MenuItem } from './MenuItem';
