import { parsePlanId } from 'core/utils/data-utils';
import {
  deleteData,
  fetchData,
  postData,
  putData,
} from '../../utils/http-utils';

function buildUrl(emplid: string, planId?: string) {
  const planIdResource = planId ? `/${parsePlanId(planId)}` : '';
  const url = `/api/${emplid}/plans${planIdResource}`;

  return url;
}

function marshalPlanResponse(response: { data: API.PlanData.Plan }): {
  plan: API.PlanData.Plan;
  setup: API.PlanData.ConfigPayload;
} {
  const { data } = response;
  const { configuration: conf } = data;

  const plan: API.PlanData.Plan = data!;
  const setup: API.PlanData.ConfigPayload = {
    id: data.uuid,
    planName: data.planName,
    configMethod: conf.method,
    includeSummerTerms: conf.includeSummerTerms,
    graduationTerm: conf.graduationTerm || '',
    creditHourTerm: conf.creditHourTerm || '',
  };

  return {
    setup,
    plan,
  };
}

/**
 * @deprecated Once the Backend will include the configuration object
 */
function marshalConfigurationPlan({
  setup,
  plan,
}: {
  setup: API.PlanData.ConfigPayload;
  plan: API.PlanData.Plan;
}) {
  if (setup && !plan.configuration) {
    plan.configuration = setup.creditHourTerm
      ? {
          includeSummerTerms: setup.includeSummerTerms,
          method: 'credit-hour-term',
          creditHourTerm: setup.creditHourTerm,
        }
      : {
          includeSummerTerms: setup.includeSummerTerms,
          method: 'graduation-term',
          graduationTerm: setup.graduationTerm,
        };
  }

  return plan;
}

async function getActivePlan(emplid: string, signal?: AbortSignal) {
  const url = `${buildUrl(emplid)}/active`;
  // ======================================================
  // TODO: BE Refactor - flat `plan` attribute into `data`
  // ======================================================
  // const { data } = await fetchData<API.HttpFlatPlanAnyResponse>(url);
  //
  // return data;
  // ======================================================
  const { data } = await fetchData<API.HttpPlanAnyResponse>({
    url,
    config: { signal },
  });

  data.plan = marshalConfigurationPlan(data);

  return data.plan;
}

async function updateActivePlan(emplid: string, planId: string) {
  const url = `${buildUrl(emplid)}/active`;
  // ======================================================
  // TODO: BE Refactor - flat `plan` attribute into `data`
  // ======================================================
  // New function body:
  // const { data } = await putData<API.HttpFlatPlanAnyResponse>(url, {
  //   id: planId,
  // });
  //
  // return data;
  // ======================================================
  const { data } = await putData<API.HttpPlanAnyResponse>(url, {
    id: planId,
  });

  return data.plan;
}

async function getPlanList(emplid: string, signal: AbortSignal) {
  const url = buildUrl(emplid);
  const { data } = await fetchData<API.HttpPlanGetResponse>({
    url,
    config: {
      signal,
    },
  });

  return data;
}

async function updatePlanVersion(
  emplid: string,
  planId: string,
  direction: HistoryDirection,
) {
  const url = buildUrl(emplid, planId);
  await putData<API.HttpFlatPlanAnyResponse>(`${url}/version`, {
    action: direction,
  });
}

async function copyPlan(emplid: string, planId: string) {
  const url = buildUrl(emplid);
  // ======================================================
  // TODO: BE refactoring - double-check attribute `uuid`
  // Is still `uuid` or changed to `planId` or `id`
  // ======================================================
  const { data } = await postData<API.HttpFlatPlanAnyResponse>(url, {
    planId,
  });

  return data.uuid;
}

async function resetPlan(emplid: string, planId: string) {
  const url = buildUrl(emplid, planId);
  await putData<API.HttpPlanAnyResponse>(`${url}/version`, {
    action: 'reset',
  });
}

async function getPlan(emplid: string, planId: string) {
  const url = buildUrl(emplid, planId);
  /* ======================================================
     TODO: BE Refactor to include  `configuration: {
     method,includeSummerTerms,graduationTerm,creditHourTerm }`
    ====================================================== */
  // New function body:
  // const { status, data } = await fetchData<API.HttpFlatPlanAnyResponse>(url);
  // const marshalResponse = data.currentPlanVersion
  //   ? marshalPlanResponse({ data })
  //   : {
  //       setup: null,
  //       plan: null,
  //     };

  // return {
  //   status,
  //   ...marshalResponse,
  // };
  // ======================================================
  const response = await fetchData<API.HttpPlanAnyResponse>(url);
  const { data, status } = response;

  data.plan = marshalConfigurationPlan(data);

  const { setup, plan } = data;
  return {
    status,
    setup,
    plan,
  };
  // ======================================================
}

async function createPlanConfiguration(
  emplid: string,
  payload: API.PlanData.ConfigPayload,
) {
  const url = buildUrl(emplid);
  // ======================================================
  // TODO: BE refactoring - double-check attribute `uuid`
  // Is still `uuid` or changed to `planId` or `id`
  // ======================================================
  const { data } = await postData<API.HttpPlanCreatePostResponse>(url, payload);

  return data.id;
}

async function updatePlanConfiguration(
  emplid: string,
  payload: API.PlanData.ConfigPayload,
) {
  const url = buildUrl(emplid, payload.id);
  // ======================================================
  // TODO: BE refactoring - double-check attribute `uuid`
  // Is still `uuid` or changed to `planId` or `id`
  // ======================================================
  const { data } = await putData<API.HttpFlatPlanAnyResponse>(
    `${url}/configuration`,
    payload,
  );
  return data.uuid;
}

async function deletePlan(emplid: string, planId: string) {
  const url = buildUrl(emplid, planId);

  await deleteData(url);

  return true;
}

export {
  getPlanList,
  getPlan,
  createPlanConfiguration,
  updatePlanConfiguration,
  deletePlan,
  getActivePlan,
  updateActivePlan,
  updatePlanVersion,
  resetPlan,
  copyPlan,
};
