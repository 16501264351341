import { NO_REPORT } from 'core/constants/app-constants';

const courseIDS = new Map<string, number>();

type PartialClass = {
  id?: string;
  subject?: string;
  catalogNumber?: string;
};

function sortClasses<T extends API.PlanData.Class>(list: T[] = []) {
  const ELECTIVE = 'Elective';
  const classes = (list || []).slice();

  classes.sort((a, b) => {
    const subjectA = a.subject || '';
    const subjectB = b.subject || '';
    const catalogNumberA = +a.catalogNumber || 0;
    const catalogNumberB = +b.catalogNumber || 0;
    const classTitleA = a.title || '';
    const classTitleB = b.title || '';

    if (classTitleA === ELECTIVE && classTitleB !== ELECTIVE) {
      return 1; // a should be after b (b is not ELECTIVE)
    }

    if (classTitleA !== ELECTIVE && classTitleB === ELECTIVE) {
      return -1; // a should be before b (a is not ELECTIVE)
    }

    if (subjectA === subjectB) {
      if (a.catalogNumber === b.catalogNumber) {
        return classTitleA.localeCompare(classTitleB, undefined, {
          sensitivity: 'base',
        });
      }
      return catalogNumberA - catalogNumberB;
    }

    return subjectA.localeCompare(subjectB, undefined, {
      sensitivity: 'base',
    });
  });

  return classes;
}

const makeCardId = (subject: string, catalogNumber: string) =>
  `${subject}:${catalogNumber}`;

const resetCounterClassUId = () => {
  courseIDS.clear();
};

const processClassUId = (sClass: PartialClass, defaultId: string) => {
  const { subject, catalogNumber } = sClass;

  // ================================================
  const cardId =
    subject && catalogNumber
      ? makeCardId(subject, catalogNumber)
      : sClass.id || defaultId;

  const counter = courseIDS.has(cardId) ? courseIDS.get(cardId)! + 1 : 0;

  courseIDS.set(cardId, counter);
  // ================================================
  const id = `card_${cardId.replace(':', '-')}`;
  const index = counter ? `_${counter}` : '';
  const classUId = `${id}${index}`;

  return { classUId, cardId };
};

const processClasses = <T extends API.PlanData.Class>({
  isTermInprogress,
  termCode,
  termUId,
  session,
  sessionUId,
  sessionPending,
  classType,
  classes = [],
  creditSource,
  defaultClassStatus,
}: {
  session: API.PlanData.Session | null;
  classType: API.PlanData.ClassType;
  isTermInprogress: boolean;
  termUId: string;
  termCode: string;
  sessionUId: string;
  sessionPending: boolean;
  classes: T[];
  creditSource: API.PlanData.CreditSource;
  defaultClassStatus?: API.PlanData.ClassStatus;
}): T[] => {
  const allowMoveInSession = session?._uiMetaData?.allowMoveInSession;
  const sessionCode = session?.sessionName || '';

  const processedClasses = classes.map((sClass, classIndex) => {
    const defaultClassId = `${sessionUId}-class-${classIndex}-of-${classType}`;
    const { classUId, cardId } = processClassUId(sClass, defaultClassId);

    sClass._uid = classUId;
    sClass.status = sClass.status || defaultClassStatus;
    sClass.creditHours = Number.isNaN(sClass.creditHours)
      ? 0
      : +sClass.creditHours.toFixed(2);

    if (sClass.status === 'completed' && !sClass.grade) {
      sClass.grade = NO_REPORT;
    }

    const displayAsNotRegistered =
      allowMoveInSession === false &&
      (sClass.status === undefined || sClass.status === 'planned');

    sClass._uiMetaData = {
      termCode,
      sessionCode,
      cardId,
      classUId,
      termUId,
      sessionUId,
      sessionPending,
      classType,
      creditSource,
      isTermInprogress,
      displayAsNotRegistered,
      creditHoursForDisplay: (classType === 'required'
        ? sClass.creditHoursRemaining || 0
        : sClass.creditHours
      ).toFixed(2),
      fulfilledRequirementNames: [],
      warning: null,
    };

    return sClass as T;
  });

  const sortedClasses = sortClasses(processedClasses);

  return sortedClasses || [];
};

function attachFulfilledRequirementNames(
  selectedClasses: API.PlanData.Class[] = [],
  requiredClasses: API.PlanData.Class[] = [],
) {
  selectedClasses.forEach((sClass) => {
    sClass.fulfilledRequirementIds?.forEach((reqId) => {
      const requirement = requiredClasses.find((req) => req.id === reqId);
      if (requirement) {
        const title = requirement.title;
        sClass._uiMetaData?.fulfilledRequirementNames.push(title);
      }
    });
  });
}

const filterRemainedRequirements = (
  requiredClasses: API.PlanData.Class[] = [],
) => {
  const displayRequirements = requiredClasses.filter((req) => {
    if ((req.creditHoursRemaining || 0) < 0.25) {
      return false;
    } else if (
      req.minCourseCountRequired &&
      (req.courseCountRemaining || 0) < 1
    ) {
      return false;
    }

    return true;
  });

  return displayRequirements;
};

export {
  attachFulfilledRequirementNames,
  filterRemainedRequirements,
  makeCardId,
  processClasses,
  resetCounterClassUId,
};
