import React, { FC } from 'react';
import { BaseButton } from 'core/components/BaseButton';

type Props = {
  shape?: 'rounded' | 'circular';
} & React.PropsWithChildren<React.ComponentPropsWithRef<'button'>>;

const PageItem: FC<Props> = ({
  className,
  children,
  shape = 'rounded',
  ...props
}) => {
  return (
    <li>
      <BaseButton
        className={`text-normal h-fit min-w-8 gap-x-0 px-1.5 ${
          shape === 'circular' ? 'rounded-full' : 'rounded'
        } ${className}`}
        {...props}
      >
        {children}
      </BaseButton>
    </li>
  );
};

export { PageItem };
