import { createSelector } from '@reduxjs/toolkit';

import { RootState, useAppSelector } from '..';

const selectUserRoles = createSelector(
  (state: RootState) => state.userProfile,
  (userProfile) => {
    const viewAsRoles = userProfile.viewAs?.selectedUser?.roles;
    const profileRoles = userProfile.roles;

    return viewAsRoles || profileRoles || [];
  },
);

const useUserRolesSelector = () => useAppSelector(selectUserRoles);

export { selectUserRoles, useUserRolesSelector };
