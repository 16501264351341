import { isElementInViewport } from 'core/utils/html-utils';
import { MenuDirection } from '../types';

type PlaceMenuParams = {
  isSubMenu: boolean;
  anchorEl: HTMLElement;
  menuList: HTMLUListElement;
  direction: MenuDirection;
  menuPortalTarget?: HTMLElement;
};

let menuEnterTimer: NodeJS.Timer;
let visibilityTimer: NodeJS.Timer;

/**
 * It computes TOP, LEFT coordinates to place the Menu beside the anchor element
 */
const placeMenuPosition = ({
  isSubMenu,
  anchorEl,
  menuList,
  direction,
  menuPortalTarget,
}: PlaceMenuParams) => {
  const rect = anchorEl.getBoundingClientRect();

  menuList.removeAttribute('data-animate-hidden');
  menuList.setAttribute('style', 'visibility: hidden;');

  if (menuPortalTarget) {
    menuList.style.top = rect.y + rect.height + 'px';

    if (direction === 'left') {
      menuList.style.right = window.innerWidth - rect.left - rect.width + 'px';
    } else {
      menuList.style.left = rect.x + 'px';
    }
  } else {
    menuList.style.top = isSubMenu
      ? `calc(-1 * var(--anchor-size-offset))`
      : 'unset';

    const offsetAnchorLeft = isSubMenu
      ? `calc(var(--anchor-size-offset) + ${rect.width}px)`
      : '0px';

    if (direction === 'left') {
      menuList.style.right = offsetAnchorLeft;
    } else {
      menuList.style.left = offsetAnchorLeft;
    }
  }
};

/**
 * It checks if the Menu body is fully visible inside the viewport
 * and adjusts the TOP, LEFT accordantly.
 */
const adjustMenuPosition = ({
  isSubMenu,
  anchorEl,
  menuList,
  direction,
}: PlaceMenuParams) => {
  const { isFullyVisible, heightFullyVisible, widthFullyVisible } =
    isElementInViewport(menuList);

  if (isFullyVisible) return;

  const rect = anchorEl.getBoundingClientRect();
  let currentDirection = direction;
  let yTranslate = '0px';
  let xTranslate = '0px';

  if (!widthFullyVisible) {
    currentDirection = direction === 'left' ? 'right' : 'left';

    if (isSubMenu) {
      if (direction === 'left') {
        menuList.style.left = 'unset';
        menuList.style.right = '0px';
        xTranslate = '100%';
      } else {
        menuList.style.left = `calc(0px - var(--anchor-size-offset))`;
        menuList.style.right = 'unset';
        xTranslate = '-100%';
      }
    } else {
      xTranslate = `calc(-100% + ${rect.width}px)`;
    }
  }

  if (!heightFullyVisible) {
    yTranslate = `calc(-100% - ${rect.height}px)`;
  }

  anchorEl.setAttribute('data-display-direction', currentDirection);
  menuList.style.transform = `translate(${xTranslate}, ${yTranslate})`;
  menuList.style.transition = 'translate 0.3s ease';
};

const showMenu = ({
  isSubMenu,
  anchorEl,
  menuList,
  direction,
  menuPortalTarget,
}: PlaceMenuParams) => {
  if (!anchorEl) return;

  placeMenuPosition({
    menuList,
    anchorEl,
    direction,
    isSubMenu,
    menuPortalTarget,
  });

  visibilityTimer = setTimeout(() => {
    adjustMenuPosition({
      menuList,
      anchorEl,
      direction,
      isSubMenu,
    });

    menuList.style.visibility = 'visible';
    menuList.setAttribute('data-animate-show', 'true');
  }, 300);
};

const animateHideMenu = (menuList: HTMLUListElement) => {
  stopDisplayTimers();

  if (menuList) {
    menuList.removeAttribute('data-animate-show');
    menuList.setAttribute('data-animate-hidden', 'true');
  }
};

const stopDisplayTimers = () => {
  clearTimeout(menuEnterTimer);
  clearTimeout(visibilityTimer);
};

export { animateHideMenu, showMenu, stopDisplayTimers };
