import { useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import planActiveReducer from './slices/planActiveSlice';
import planSetupReducer from './slices/planSetupSlice';
import planReducer from './slices/planSlice';
import sharedReducer from './slices/sharedSlice';
import studentProfileReducer from './slices/studentProfileSlice';
import termsSliceReducer from './slices/termsSlice';
import userProfileReducer from './slices/userProfileSlice';

// Set up store
const setupStore = (preloadedState?: Partial<AppState>) => {
  return configureStore({
    reducer: {
      userProfile: userProfileReducer,
      studentProfile: studentProfileReducer,
      plan: planReducer,
      planSetup: planSetupReducer,
      planActive: planActiveReducer,
      shared: sharedReducer,
      terms: termsSliceReducer,
    },
    preloadedState,
  });
};

const store = setupStore();
store.getState();

// # Utilities to handle the state
type AppDispatch = typeof store.dispatch;

// ## dispatch an action
const useAppDispatch: () => AppDispatch = useDispatch;

// ## select the app state
type RootState = ReturnType<typeof store.getState>;
const useAppSelector = <TState = RootState, TSelected = unknown>(
  selector: (state: TState) => TSelected,
) => {
  return useSelector(selector);
};

// ## select a slice of contactInfo
const useContactInfoSelector = (
  key: StringKeyOf<Required<API.StudentData.Profile>>,
) => {
  return useAppSelector((state) => state.studentProfile.data[key!] as string);
};
const useContactPreferredCommunication = (
  key: keyof API.StudentData.PreferredCommunication,
) => {
  return useAppSelector(
    (state) =>
      state.studentProfile.data.preferredCommunication?.[key] as string,
  );
};

export {
  store,
  setupStore,
  useAppSelector,
  useAppDispatch,
  useContactInfoSelector,
  useContactPreferredCommunication,
  type RootState,
};
