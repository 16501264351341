import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import 'App.css';
import { ProtectedRoute } from 'core/auth';
import { LazyRoute } from 'core/components';
import { roleList, RoleName } from 'core/constants/app-constants';
import { appRouterUrl } from 'core/constants/router-url';
import { useScrollbarSettings } from 'core/hooks/use-scrollbar-settings';
import { useWebpackError } from 'core/hooks/use-webpack-error';
import { useAppDispatch } from 'core/store';
import {
  useCurrentRoleSelector,
  useDashboardUrlSelector,
} from 'core/store/selectors';
import { setSelectedRoute, setShowNavbar } from 'core/store/slices';
//#region "pages"
import { PageLayout } from 'pages/PageLayout';

const Dashboard = React.lazy(() => import('./pages/Student/Dashboard'));
const PlanDetail = React.lazy(() => import('./pages/Student/PlanDetail'));
const StudentProfile = React.lazy(() => import('./pages/Student/Profile'));
const AdminDashboard = React.lazy(() => import('./pages/Admin/Dashboard'));
const AdminProfileView = React.lazy(() => import('./pages/Admin/ProfileView'));
const NotAuthorized = React.lazy(() => import('./pages/NotAuthorized'));
const NotFound = React.lazy(() => import('./pages/NotFound'));
const LogOut = React.lazy(() => import('./pages/LogOut'));
//#endregion

const allRoles = roleList;
const adminRoles = [RoleName.ADMIN];
const advisorRoles = [RoleName.ADVISOR, RoleName.COACH];

function App() {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const currentRole = useCurrentRoleSelector();
  const dashboardPage = useDashboardUrlSelector();

  useWebpackError();
  useScrollbarSettings();

  useEffect(() => {
    if (pathname === appRouterUrl.HOME) {
      dispatch(setShowNavbar(true));
    }
    dispatch(setSelectedRoute(pathname));
  }, [dispatch, pathname]);

  return (
    <Routes>
      <Route path="/" element={<PageLayout />}>
        <Route
          path={appRouterUrl.HOME}
          element={
            <ProtectedRoute roles={allRoles} lazy>
              <Navigate to={dashboardPage} />
            </ProtectedRoute>
          }
        />

        {/* =============================================
                         "Student pages"
            ============================================= */}
        <Route
          path={appRouterUrl.STUDENT_PROFILE}
          element={
            <ProtectedRoute
              viewAsRole={currentRole}
              roles={[RoleName.STUDENT]}
              lazy
            >
              <StudentProfile />
            </ProtectedRoute>
          }
        />

        <Route
          key={appRouterUrl.STUDENT_DASHBOARD}
          path={appRouterUrl.STUDENT_DASHBOARD}
          element={
            <ProtectedRoute
              viewAsRole={currentRole}
              roles={[RoleName.STUDENT]}
              lazy
            >
              <Dashboard />
            </ProtectedRoute>
          }
        />

        <Route
          key={appRouterUrl.PLAN_DETAIL_BY_PLAN_ID}
          path={appRouterUrl.PLAN_DETAIL_BY_PLAN_ID}
          element={
            <ProtectedRoute
              viewAsRole={currentRole}
              roles={[RoleName.STUDENT]}
              lazy
            >
              <PlanDetail />
            </ProtectedRoute>
          }
        />

        <Route
          path={appRouterUrl.PLAN_VIEW_BY_EMPLID_PLAN_ID}
          element={
            <ProtectedRoute roles={[...adminRoles, ...advisorRoles]} lazy>
              <PlanDetail />
            </ProtectedRoute>
          }
        />

        {/* =============================================
                         "Admin pages"
            ============================================= */}
        <Route
          path={appRouterUrl.ADMIN_DASHBOARD}
          element={
            <ProtectedRoute roles={[...adminRoles, ...advisorRoles]} lazy>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />

        <Route
          path={appRouterUrl.ADMIN_PROFILE_VIEW_BY_ID}
          element={
            <ProtectedRoute roles={[...adminRoles, ...advisorRoles]} lazy>
              <AdminProfileView />
            </ProtectedRoute>
          }
        />

        {/* =============================================
                         "Other pages"
            ============================================= */}
        <Route
          path={appRouterUrl.LOG_OUT}
          element={
            <LazyRoute>
              <LogOut />
            </LazyRoute>
          }
        />
        <Route
          path={appRouterUrl.NOT_AUTHORIZED}
          element={
            <LazyRoute>
              <NotAuthorized />
            </LazyRoute>
          }
        />
        {['*', appRouterUrl.NOT_FOUND].map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <LazyRoute>
                <NotFound />
              </LazyRoute>
            }
          />
        ))}
      </Route>
    </Routes>
  );
}

export default App;
