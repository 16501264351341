import { FC, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useAuthContext } from 'core/auth';
import { RoleName } from 'core/constants/app-constants';
import { appRouterUrl as urls } from 'core/constants/router-url';
import { useAppDispatch, useAppSelector } from 'core/store';
import { useCurrentRoleSelector } from 'core/store/selectors';
import { setSelectedRoute } from 'core/store/slices';
import loggedOutLogo from 'img/asu-logo/asu-sunburst-maroon-gold.png';

import {
  NavMenuItem,
  SlideEffectMenubar,
  SlideEffectMenuGroup,
} from '../SlideEffectMenubar';
import { RightNavbar } from './components/RightNavbar';
import { TopNavbar } from './components/TopNavbar';
import styles from './index.module.scss';

type NavbarRoute = NavMenuItem;

const docStyle = document.documentElement.style;

function computeNavbarHeight() {
  const topNavbar = document.querySelector<HTMLElement>('#top-navbar');
  const headerBar = document.querySelector<HTMLElement>('#main-navbar');

  let maxNavHeight = '0px',
    topNavbarHeight = topNavbar?.clientHeight || 0,
    mainNavbarHeight = headerBar?.clientHeight || 0;
  if (!headerBar) {
    console.warn('Navbar not found');
    return maxNavHeight;
  }
  // set header scrolling margin
  maxNavHeight = mainNavbarHeight + topNavbarHeight + 'px';

  docStyle.setProperty('--top-navbar-height', topNavbarHeight + 'px');
  docStyle.setProperty('--main-navbar-height', mainNavbarHeight + 'px');

  return maxNavHeight;
}

function computeMarginOffset() {
  const currentNavbarHeight = computeNavbarHeight();
  docStyle.setProperty('--navbar-height', currentNavbarHeight);
}

function storeInitialNavbarHeight() {
  const currentNavbarHeight = computeNavbarHeight();
  docStyle.setProperty('--initial-navbar-height', currentNavbarHeight);
}

//#region "Navtree role based"
function buildNavbar(
  role: UserProfileRole,
  selectedRoute: string,
): NavbarRoute[] {
  const buildRoute = (id: string, routePath: string, text: string) => ({
    id,
    text,
    routePath,
    selected: selectedRoute === routePath,
  });

  const roleRouteLinks: Record<string, () => NavbarRoute[]> = {
    [RoleName.ADMIN]: () => {
      return [buildRoute('ad-dashboard', urls.ADMIN_DASHBOARD, 'Dashboard')];
    },
    [RoleName.ADVISOR]: () => {
      return [buildRoute('ad-dashboard', urls.ADMIN_DASHBOARD, 'Dashboard')];
    },
    [RoleName.COACH]: () => {
      return [buildRoute('ad-dashboard', urls.ADMIN_DASHBOARD, 'Dashboard')];
    },
    [RoleName.STUDENT]: () => {
      return [buildRoute('st-dashboard', urls.STUDENT_DASHBOARD, 'Dashboard')];
    },
  };

  const navTree = roleRouteLinks[role]?.() || [];

  return navTree;
}
//#endregion

const Navbar: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    state: { isAuthenticated, user, returnUrl },
  } = useAuthContext();
  const showNavbar = useAppSelector((state) => state.shared.showNavbar);
  const selectedRoute = useAppSelector(
    (state) => state.shared.selectedRoute || '',
  );
  const currentRole = useCurrentRoleSelector();
  const navTree = useMemo(
    () =>
      isAuthenticated && currentRole
        ? buildNavbar(currentRole, selectedRoute)
        : [],
    [isAuthenticated, currentRole, selectedRoute],
  );

  useEffect(() => {
    storeInitialNavbarHeight();

    return () => {
      computeMarginOffset();
    };
  }, []);

  useEffect(() => {
    const mainNavbar = document.querySelector('#main-navbar')!;
    const resizeObserver = new ResizeObserver(computeMarginOffset);
    resizeObserver.observe(mainNavbar);

    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    returnUrl && dispatch(setSelectedRoute(returnUrl));
  }, [dispatch, returnUrl]);

  return (
    <>
      <TopNavbar id="top-navbar" className="z-100" userName={user || ''} />
      <header
        id="main-navbar"
        className={`${styles.header} z-100 border-bottom-gray-4 sticky inset-x-0 top-0 flex h-fit items-center bg-white`}
        data-show-navbar={showNavbar}
      >
        <nav className="ml-4 flex">
          <Link to={urls.HOME} className="leading-none">
            <img className="me-3 h-6 w-14" alt="logo" src={loggedOutLogo} />
          </Link>
          <h1
            className={`${styles.title} my-0 me-7 text-2xl font-bold tracking-tight`}
          >
            <Link to={urls.HOME}>Interactive Degree Planner</Link>
          </h1>
        </nav>

        <SlideEffectMenuGroup className="flex flex-grow justify-between px-4 py-5">
          <SlideEffectMenubar
            className="fade-show"
            data-hidden={navTree.length === 0}
            items={navTree}
            onItemClick={(item) => navigate(item.routePath!)}
          />
          <RightNavbar
            className="fade-show"
            data-hidden={isAuthenticated === false}
          />
        </SlideEffectMenuGroup>
      </header>
    </>
  );
};

export { Navbar };
