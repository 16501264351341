/**
 * @deprecated
 * TODO: DO NOT USE. Planning to remove
 */
const findListItemParent = (anchorEl: HTMLElement) => {
  let parentItem: HTMLElement | null | undefined = anchorEl.parentElement;
  let level = 0;

  do {
    parentItem = parentItem?.parentElement;
    level++;
    if (
      parentItem &&
      parentItem.nodeName === 'LI' &&
      parentItem.hasAttribute('data-menu-item')
    ) {
      break;
    }
  } while (level < 10);

  const parentRect = parentItem?.getBoundingClientRect() ?? {
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0,
  };

  return { parentItem, parentRect };
};

const findActiveSibling = (
  target: HTMLElement,
  sideSibling: 'previousSibling' | 'nextSibling',
) => {
  let sibling = target;
  let isDisabled;

  do {
    sibling = sibling[sideSibling] as HTMLElement;
    isDisabled = sibling?.getAttribute('data-disabled');

    if (isDisabled === 'false' || !isDisabled) {
      break;
    }
  } while (sibling);

  return sibling;
};

export { findListItemParent, findActiveSibling };
