import { FC } from 'react';
import ReactSelect, { ActionMeta, components } from 'react-select';

import { generateId } from 'core/utils/generate-id';
import { dropdownStyles } from './dropdown-config';
import styles from './index.module.scss';

type Props = Omit<import('react-select').Props, 'options' | 'onChange'> & {
  onChange:
    | ((newValue: OptionItem, actionMeta: ActionMeta<unknown>) => void)
    | undefined;
  options: OptionItem[];
};

const Dropdown: FC<Props> = ({
  id,
  className = '',
  onChange,
  value,
  styles: customStyles = {},
  ...props
}) => {
  const dropdownId = id || generateId('dropdown-');

  const valueItem =
    typeof value === 'string'
      ? props.options?.find((v) => (v as OptionItem).value === value)
      : value;

  return (
    <ReactSelect
      id={dropdownId}
      value={valueItem}
      className={`react-select ${className}`}
      isClearable
      components={{
        IndicatorSeparator: (props) => (
          <components.IndicatorSeparator
            {...props}
            className="react-select-indicator-separator"
          />
        ),
        IndicatorsContainer: (props) => (
          <components.IndicatorsContainer
            {...props}
            className="react-select-indicators-container"
          />
        ),
        SingleValue: (props) => (
          <components.SingleValue
            {...props}
            className="react-select-single-value"
          />
        ),
        Placeholder: (props) => (
          <components.Placeholder
            {...props}
            className="react-select-place-holder"
          />
        ),
        Control: (props) => (
          <components.Control {...props} className="react-select-control" />
        ),
        Input: (props) => (
          <components.Input
            {...props}
            className="react-select-input custom-input"
          />
        ),
        Menu: (props) => (
          <components.Menu {...props} className={styles['menu']} />
        ),
        ...props.components,
      }}
      styles={{ ...dropdownStyles, ...customStyles }}
      classNames={{
        option: (state) => {
          if (state.isDisabled) return `disabled`;
          if (state.isFocused) return `focused`;
          return '';
        },
      }}
      onChange={(newValue, actionMeta) => {
        let item = newValue;

        if (actionMeta.action === 'clear') {
          item = {
            value: null,
          };
        }

        onChange?.(item as OptionItem, actionMeta);
      }}
      {...props}
    />
  );
};

export { Dropdown };
