import { createSelector } from '@reduxjs/toolkit';

import { RootState, useAppSelector } from '..';
import { studentDefaultState } from '../slices/studentProfileSlice';

const selectCurrentProfile = createSelector(
  [
    (state: RootState) => state.userProfile,
    (state: RootState) => state.studentProfile,
    (_state: RootState, useSearchProfile?: boolean) => useSearchProfile,
  ],
  (userProfile, studentProfile, useSearchProfile = false) => {
    const majorTypes: Record<string, string> = {
      MAJ: 'Major',
    };

    let selectedUserRoles: API.UserRole[] = [];
    const { search, viewAs } = userProfile;
    let profile: EveryProfile;

    if (useSearchProfile) {
      profile = search.selectedUser?.profile;
      selectedUserRoles = search.selectedUser?.roles || [];
    } else if (viewAs.active) {
      profile = viewAs.selectedUser?.profile;
      selectedUserRoles = viewAs.selectedUser?.roles || [];
    } else {
      profile = studentProfile.data;
      selectedUserRoles = userProfile?.roles || [];
    }

    const major =
      profile?.declaredDegreePrograms.declaredMajors?.[0] ||
      ({
        name: '',
        degree: '',
        type: '',
        campusName: '',
        academicPlanCode: undefined,
      } satisfies Partial<API.StudentData.DegreeProgram>);

    const majorName =
      major.name && major.degree ? `${major.name}, ${major.degree}` : '';
    const majorType = majorTypes[major.type] || 'Major';

    return {
      profile: profile || studentDefaultState.data,
      selectedUserRoles,
      major,
      majorName,
      majorType,
    };
  },
);

const useCurrentProfileSelector = (useSearchProfile = false) =>
  useAppSelector((state) => selectCurrentProfile(state, useSearchProfile));

export { selectCurrentProfile, useCurrentProfileSelector };
